import wetenschapscommunicatie from "../Pictures/wetenschapscommunicatie.JPG"
import care from "../Pictures/care.JPG"
import marketing from "../Pictures/marketing.JPG"
import resto from "../Pictures/restoquest.JPG"
import vormtaal from "../Pictures/vormtaal.JPG"
import training from "../Pictures/training.JPG"

function Communication(){
    return(
    <div className="boxWithContent" style={{color:"beige", backgroundColor:"#6fbbd3"}}>
        <div style={{margin:"1rem"}}>
                    <h1 >Communication Design</h1>
                    <h2 style={{color:"lightblue"}}> 2020-2023</h2>
        </div>

        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:"space-evenly", alignItems:"flex-start", width:"100%"}}>
                   
                    <div className="imageWrapper" style={{backgroundColor:"beige"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={vormtaal} alt="Sensemaking " style={{border: "0.5rem solid lightblue"}} />
                        </div>
                        <div className="imageWrapperText" style={{color: "#6fbbd3"}}>
                            <h2>Afstudeerproject Betekenisgeving in een educatiemodule</h2>
                            <p>Voor mijn afstuderen heb ik een advies ontwikkeld voor een educatiemodule die gebruikt kan worden 
                                in het universitair onderwijs. In de educatiemodule zit ook het gebruik van Artificial Intelligence. 
                                Het bestaat uit groepen die gefaciliteerd worden door een facilitator en die gebruik maken van een 
                                haptische taal (Vormtaal). Deze taal wordt gebruikt om studenten inzicht te geven hoe ze betekenisgeven
                                aan hun team en het project. Kunstmatige intelligentie wordt gebruikt op de beeldmateriaal om de facilitator
                                extra inzich te geven in de betekenissen en eventueel gemiste betekenisgevingen. De module heb ik ontworpen
                                door een op wetenschappelijk onderzoek gebaseerd protocol te gebruiken bij focusgroepen van studenten en daar 
                                een rapport van te schrijven.
                            </p>
                        </div>
                    </div>

                    <div className="imageWrapper" style={{backgroundColor:"beige"}}>
                        <div style={{display:"flex", flexDirection:"column", alignContent:"center"}}>
                            <img className="responsive" src={training} alt="trainings " style={{border: "0.5rem solid lightblue"}} />
                            <a href="https://docs.google.com/spreadsheets/d/1EraeqlIfcWbOWfrHHctZbtNFYLYtrps_iNtsI6zf3E8/edit?usp=sharing">Link naar alle trainingen</a>
                        </div>
                        
                        <div className="imageWrapperText" style={{color: "#6fbbd3"}}>
                            <h2>Soft Skill trainingen (2017-2020)</h2>
                            <p>Drie jaar lang heb ik vrijwillig soft skill trainingen ontwikkeld (communicatie, timemanagement) voor lokale
                                verenigingen van de europese elektrotechniek en informatica studentenvereniging EESTEC. Dit deed ik meestal
                                in samenwerking met één andere trainer. Het leukste aan deze trainingen vond ik het ontwerpen van de trainingen
                                waarbij een interactieve leervorm wordt gebruikt (denk aan opdrachten en discussies). Het motiveerde mij om 
                                anderen te helpen bij hun persoonlijke ontwikkeling en hiervoor een veilige leeromgeving te creëeren.
                                
                            </p>
                            
                        </div>
                    </div>
                    
                    <div className="imageWrapper" style={{backgroundColor:"beige"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={wetenschapscommunicatie} alt="wetenschapscommunicatie " style={{border: "0.5rem solid lightblue"}} />
                        </div>
                        <div className="imageWrapperText" style={{color: "#6fbbd3"}}>
                            <h2>Introductie in Wetenschapscommunicatie: interview met stakeholders voor de nieuwe OK</h2>
                            <p>Voor dit project interviewden we in groepjes verschillende belanghebbenden bij het ontwikkelen van een nieuwe operatiekamer.
                                Mijn groepje interviewde twee dokters om hun perspectief te krijgen over het project. Dit was voor het eerst dat ik een 
                                interviewprotocol heb opgesteld en interviewresultaten heb gebruikt voor het presenteren van een perspectief van een 
                                groep mensen.
                            </p>
                        </div>
                    </div>

                    <div className="imageWrapper" style={{backgroundColor:"beige"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={care} alt="essentie " style={{border: "0.5rem solid lightblue"}} />
                        </div>
                        <div className="imageWrapperText" style={{color: "#6fbbd3"}}>
                            <h2>Ontwikkelen communicatiestrategie Vopak</h2>
                            <p>Voor dit project hebben we theorie en een ontwerpproces gebruikt om tot een communicatiestrategie te komen
                                wat past bij Vopak. Hier werkte we dus met een echte opdrachtgever waarvoor we een strategie ontwikkelden.
                                Bij dit ontwerp proces werkte ik voor het eerst met design thinking (de Double Diamond), waarbij we eerst 
                                een essentie van het probleem hebben gevonden (Care in ons geval) en ten slotte een passende oplossing
                                hebben gepresenteerd.
                            </p>
                        </div>
                    </div>

                    <div className="imageWrapper" style={{backgroundColor:"beige"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={marketing} alt="marketing " style={{border: "0.5rem solid lightblue"}} />
                        </div>
                        <div className="imageWrapperText" style={{color: "#6fbbd3"}}>
                            <h2>Marketing strategie masterprogramma</h2>
                            <p>Voor dit vak hebben we een marketingstrategie ontwikkeld voor het masterprogramma om zich beter 
                                in de markt te plaatsen. Wij keken naar het perspectief van verschillende faculteiten ten opzichte
                                van dit masterprogramma. We hebben docenten van verschillende faculteiten geïnterviewd; ik heb 
                                er voor het eerst 4 geïnterviewd waaruit inzichten werden gehaald om te gebruiken in ons verslag
                                over hoe het masterprogramma beter gemarket kan worden.
                            </p>
                        </div>
                    </div>

                    <div className="imageWrapper" style={{backgroundColor:"beige"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={resto} alt="Serious Game " style={{border: "0.5rem solid lightblue"}} />
                        </div>
                        <div className="imageWrapperText" style={{color: "#6fbbd3"}}>
                            <h2>Keuzevak Serious game</h2>
                            <p>Dit vak is een informaticavak waarbij een serious game werd gebouwd voor een opdrachtgever. Onze opdrachtgever
                                is een onderzoeksgroep in Leiden die onderzoekt of virtuele natuurlijke omgevingen ook rust kan bieden
                                aan patienten. Wij hebben de game gebouwd gebaseerd op de eigenschappen van een natuurlijke omgeving dat
                                ontspanning zou geven. Het is gebouwd in Unity. Hier is ook een paper van gepresenteerd op ISAGA 2022.
                            </p>
                        </div>
                    </div>

                   

                    
        </div>
    </div>
    )
}

export default Communication;