import intersection from "../Pictures/intersection-mobile.png";
import turf from "../Pictures/turf.png"
import network from "../Pictures/network.png"
import netlogo from "../Pictures/netlogo.png"
import etvapp from "../Pictures/ETVapp.jpg"

// TODO: Use one language
// TODO: When one hovers over a box, this should overlap the rest of the boxes instead of push them away.
// TODO: Reduce the width of the borders around the pictures.
function IT(){
    return(
        <div className="boxWithContent" style={{color:"#6fbbd3", backgroundColor:"beige"}}>
                <div style={{margin:"1rem"}}>
                    <h1 >IT Projecten</h1>
                    <h2 style={{color:"lightblue"}}> 2019-2023</h2>
                </div>
                

                <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:"space-evenly", alignItems:"flex-start", width:"100%"}}>
                   
                    <div className="imageWrapper" style={{backgroundColor:"lightblue"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={intersection} alt="studyassociationwebsite " style={{border: "0.5rem solid beige"}} />
                        </div>
                        <div className="imageWrapperText" style={{color: "beige"}}>
                            <h2>Freelance Website Study Association</h2>
                            <p> De studievereniging van wetenschapscommunicatie en educatie had een nieuwe website gemaakt en ik 
                                heb er zelfstandig een nieuwe website geprogrammeerd in Gatsby (een library van React) en heb een
                                Google Calendar plugin geïntegreerd en MDX (low-code programming).  </p>
                        </div>
                    </div>

                    <div className="imageWrapper" style={{backgroundColor:"lightblue"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={network} alt="network python" height="250px" style={{border: "0.5rem solid beige"}}/>
                        </div>
                        <div className="imageWrapperText" style={{color: "beige"}}>
                                <h2>IT opdracht master</h2>
                                <p>In een team van 5 ontwikkelde we een nieuw algoritme om te bepalen waar een fictief bedrijf het beste
                                    een nieuw filiaal kon oprichten. Dit is geprogrammeerd in Python. Mijn rol was het visualiseren van 
                                    het netwerk dat de data makkelijk gezien kan worden in een diagram en meewerken aan een nieuwe variabele  
                                    bedenken waar het bedrijf naar kan kijken.
                                </p>
                        </div>
                    </div>

                    <div className="imageWrapper" style={{backgroundColor:"lightblue"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={turf} alt="turfwebsite " height="250px" style={{border: "0.5rem solid beige", height:"auto", maxWidth:"250px", width:"100%"}}/>
                        </div>
                        <div className="imageWrapperText" style={{color: "beige"}}>
                            <h2>Website voor het bijhouden van het aantal drankjes dat gedronken is</h2>
                            <p>Hobbyproject waarbij ik het ontwerp heb gemaakt en de frontend heb geprogrammeerd om het er mooi uit te laten zien. 
                                Gebruik gemaakt van Canva en React.
                            </p>
                            {/* <h2>Website for recording amount of drinks</h2>
                            <p>Friends that are distributed over different houses that regularly meet, want to have a system
                                that records where what beverage/food is taken and who has taken that. My friend and I worked
                                on that where we brainstormed on the data structure and I was responsible for all the front-end in React and uses Tailwind.
                                It is especially designed for mobile phones and now used daily.</p> */}
                        </div>
                    </div>

                    <div className="imageWrapper" style={{backgroundColor:"lightblue"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={netlogo} alt="netlogo" height="250px" style={{border: "0.5rem solid beige"}}/>
                        </div>
                        <div className="imageWrapperText" style={{color: "beige"}}>
                            <h2>IT opdracht opleiding: Agent Based Modelling</h2>
                            {/* <p>With a team of three, we had the assignment to find out how an agricultural system would behave
                                if circular strategies would be implemented. We had a case owner for which we made this model.
                                Papers were researched in which data was presented, this was translated to a scheme from which
                                we coded in Netlogo (a programming language that is focused on visually modelling a system). 
                                The result can be seen above. This was designed for a water management consultancy. </p> */}
                            <p>Voor een waterbedrijf met een team van 3 studenten hebben we een model ontwikkeld die laat zien wat
                            gedragingen zijn van landbouwbedrijven bij het implementeren van duurzame methodes. We ontwikkelde dit
                            voor een opdrachtgever en gebruikte wetenschappelijke literatuur als onderbouwing. Dit werd geprogrammeerd
                            in Netlogo.</p>
                        </div>
                    </div>
                    <div className="imageWrapper" style={{backgroundColor:"lightblue"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={etvapp} alt="ETVapp" height="250px" style={{border: "0.5rem solid beige"}}/>
                        </div>
                        <div className="imageWrapperText" style={{color: "beige"}}>
                            <h2>Ontwerp studievereniging applicatie</h2>
                            <p>Met z'n tweëen hebben we een opzet gedaan voor een mobiele applicatie. Mijn rol was de frontend en het ontwerp. </p>
                        </div>
                    </div>
                </div>

                    

                    
        </div>

        
    )
}

export default IT;