import bap from "../Pictures/BAP.jpg";
import epo from "../Pictures/EPO.jpg";


function Engineering(){
    return(
        
        <div className="boxWithContent" style={{color:"#6fbbd3", backgroundColor:"lightblue"}}>
                <div style={{margin:"1rem"}}>
                    <h1 >Electrical Engineering</h1>
                    <h2 style={{color:"beige"}}>2013-2020</h2></div>
                <div className="wrapperImageWrapper">
                    <div className="imageWrapper" style={{backgroundColor:"beige"}}>
                        <div  style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={bap} alt="BAP " height="250px" width="100%" style={{border: "0.5rem solid #6fbbd3"}}/>
                        </div>
                        <div className="imageWrapperText" style={{color: "#6fbbd3", }}>
                            <h2>Capacitive Sensor for Ventilation Ducts to measure grease thickness: Bachelor End Project.</h2>
                            <p>In pairs different sensors were designed and I was part of the whole development process of this sensor.
                                First literature research was done, then different prototypes were designed and tested in experiments to yield
                                the capacitance that is connected with what kind of grease thickness. This was reported and presented for a jury.
                            </p>
                        </div>
                    </div>

                    <div className="imageWrapper" style={{backgroundColor:"beige"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <img className="responsive" src={epo} alt="EPO " height="100%" width="100%" style={{border: "0.5rem solid #6fbbd3"}}/>
                        </div>
                        <div className="imageWrapperText" style={{color: "#6fbbd3"}} >
                        <h2>Electrical Engineering Project: Self-driving car</h2>
                            <p>Part of this project was to calculate the distance between a microphone and a speaker using fourier transformations
                                in Matlab. This was a challenging, but fun learning experience. In the end it was too difficult to make localization
                                work on a driving car, but when the car was standing still, distances could be calculated.
                                </p><p>
                                Another project in my bachelor was designing a Chip - first using FPGA's and a specialized program to design 
                                the chip (we made bomberman and I was responsible for the layout of the playing field). Programming a robot car in C 
                                to find a route from A to B. The last big project was designing a speaker (amplifier, filters etc.).
                            </p>
                        </div>
                    </div>
                </div>
        </div>
        
    )
}

export default Engineering;