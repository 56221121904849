import Grid from '@mui/material/Unstable_Grid2/Grid2';
import "./App2.css"
import Engineering from './Slides/Engineering';
import IT from './Slides/IT';
import me from "./Pictures/me.jpg";
import linkedin from "./Pictures/linkedin.png";
import Communication from './Slides/Communication';


function App() {
  return (
    <Grid container>
      <Grid item xs={2} md={2}>
        <div className="boxWithoutContent" style={{backgroundColor: "#6fbbd3", color: '#6fbbd3', height:"100vh"}}>.</div>
      </Grid>
      <Grid item xs={8} md={8}>
        <div className="boxWithContent" style={{
          backgroundColor: "white",
          color: "#6fbbd3",
          justifyContent:"center", marginLeft:"1rem", marginRight:"1rem" , height:"100vh"
          }}>
            <h1>Portofolio Projecten</h1>
            <Grid container direction="row">
              <Grid item md={6}><img src={me} alt="me" width="90%"></img></Grid>
              <Grid item md={6}  style={{padding:'2rem'}}>

                <div><a href="#Communication">Communication</a></div>
                <div className="margins"><a href="#IT">IT</a></div>
                <div className="margins"><a href="#ElectricalEngineering">ElectricalEngineering</a></div>

              </Grid>
            </Grid>
            
            <Grid container spacing={0} style={{paddingTop:"1rem"}}>
              <Grid item xs={6} md={11}><p style={{ fontSize:"1.5rem", fontWeight:"normal", color:"#6fbbd3", margin:0}}>Conchita Martin Hoogerwaard</p></Grid>
              <Grid item xs={4} md={1} >
                <a href="https://www.linkedin.com/in/cmartinhoogerwaard/" style={{border:0}} >
                <img src={linkedin} width="30rem" alt="me"  />
                </a>
              </Grid>
              
              
            </Grid>
        </div>
      </Grid>
      <Grid item xs={1}>
        <div className="boxWithoutContent" style={{backgroundColor: "lightblue", color:"lightblue" , height:"100vh"}}>.</div>
      </Grid>
      <Grid item xs={1}>
        <div className="boxWithoutContent" style={{backgroundColor: "beige", color: 'beige', height:"100vh"}}>.</div>
      </Grid>
      {/* Connections in between */}
      <Grid item xs={10}>
        <div style={{backgroundColor: "#6fbbd3", height:"5vw", color: "lightblue"}}>.</div>
      </Grid>
      <Grid item xs={1}>
        <div style={{backgroundColor: "lightblue", height:"5vw", color: "#6fbbd3"}}>.</div>
      </Grid>
      <Grid item xs={1}>
        <div style={{backgroundColor: "beige", height:"5vw", color: "beige"}}>.</div>
      </Grid>
      {/* Communication projects */}
      <Grid item xs={10} id="Communication">
        <Communication/>
      </Grid>
      <Grid item xs={1}>
      <div className="boxWithoutContent" style={{backgroundColor: "lightblue", color:"lightblue"}}>.</div>
      </Grid>
      <Grid item xs={1}>
        <div className="boxWithoutContent" style={{backgroundColor: "beige", color:"beige"}}>.</div>
      </Grid>

      {/* Connections in between */}
      <Grid item xs={2}>
        <div style={{backgroundColor: "#6fbbd3", height:"5vw", color: "#6fbbd3"}}>.</div>
      </Grid>
      <Grid item xs={9}>
        <div style={{backgroundColor: "lightblue", height:"5vw", color: "lightblue"}}>.</div>
      </Grid>
      <Grid item xs={1}>
        <div style={{backgroundColor: "beige", height:"5vw", color: "beige"}}>.</div>
      </Grid>
      {/* IT projects */}
      <Grid item xs={2}>
        <div className="boxWithoutContent" style={{backgroundColor: "#6fbbd3", color: '#6fbbd3'}}>.</div>
      </Grid>
      <Grid item xs={1}>
        <div className="boxWithoutContent" style={{backgroundColor: "lightblue", color:"lightblue"}}>.</div>
      </Grid>
      <Grid item xs={9} id="IT">
        <IT/>
      </Grid>
      {/* Transition */}
      <Grid item xs={2}>
        <div style={{backgroundColor: "#6fbbd3", height:"5vw", color: "#6fbbd3"}}>.</div>
      </Grid>
      <Grid item xs={9}>
        <div style={{backgroundColor: "lightblue", height:"5vw", color: "lightblue"}}>.</div>
      </Grid>
      <Grid item xs={1}>
        <div style={{backgroundColor: "beige", height:"5vw", color: "beige"}}>.</div>
      </Grid>


      {/*Engineering */}

      <Grid item xs={2}>
        <div className="boxWithoutContent" style={{backgroundColor: "#6fbbd3", color: "lightblue"}}>.</div>
      </Grid>
      <Grid item xs={9} id="ElectricalEngineering">
          <Engineering/>

      </Grid>
      <Grid item xs={1}>
        <div className="boxWithoutContent" style={{backgroundColor: "beige", color: "beige"}}>.</div>
      </Grid>
      {/* Last page: statement & connect
      <Grid item xs={6}>
        <div  style={{backgroundColor: "lightblue",
         color: "white", 
         width:"99%", 
         height:"100vh", 
         fontWeight:"bolder", 
         fontSize:"3vw", 
         display: "flex", 
         justifyContent: "flex-end", flexDirection:"row", alignItems:"center"}}>Good quality technical designs, combined with clear communication & design principles</div>

      </Grid>
      <Grid item xs={6}>
      <div style={{backgroundColor: "lightblue",
         color: "white", 
         width:"100%", 
         height:"100vh", 
         fontWeight:"normal", 
         fontSize:"3vw", 
         display: "flex", 
         justifyContent: "flex-start", flexDirection:"row", alignItems:"center"}}> HI</div>
         </Grid> */}
  </Grid>
    // Facilitation: Below IT stuff, put training parts I loved, experience of Leangame etc.
  );
}

export default App;
